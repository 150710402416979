import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ContentClient, Blog } from '../api/gen/index.greengold';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface BlogsState {
    isLoading: boolean;
    startIndex?: number;
    blogs: Blog[] | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestBlogsAction {
    type: 'REQUEST_BLOGS';
    startIndex: number;
}

interface ReceiveBlogsAction {
    type: 'RECEIVE_BLOGS';
    startIndex: number;
    blogs: Blog[] | undefined;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestBlogsAction | ReceiveBlogsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestBlogs: (startIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.blogs && startIndex !== appState.blogs.startIndex) {
            new ContentClient().getBlogs(startIndex)
                .then(data => {
                    dispatch({ type: 'RECEIVE_BLOGS', startIndex: startIndex, blogs: data });
                });

            dispatch({ type: 'REQUEST_BLOGS', startIndex: startIndex });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: BlogsState = { blogs: undefined, isLoading: false };

export const reducer: Reducer<BlogsState> = (state: BlogsState | undefined, incomingAction: Action): BlogsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_BLOGS':
            return {
                startIndex: action.startIndex,
                blogs: state.blogs,
                isLoading: true
            };
        case 'RECEIVE_BLOGS':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.startIndex === state.startIndex) {
                return {
                    startIndex: action.startIndex,
                    blogs: action.blogs,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
