import * as React from 'react';
import { connect } from 'react-redux';
import * as ConfigStore from '../store/Config';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { Configuration, UserAgentApplication, AuthenticationParameters } from 'msal';
import { ApplicationState } from '../store';

type ConfigProps =
  ConfigStore.ConfigState // ... state we've requested from the Redux store
  & typeof ConfigStore.actionCreators; // ... plus incoming routing parameters

type LoginState = {
    isOpen?: boolean;
    name?: string | undefined;
    isLoggedIn: boolean;
}

declare global {
    interface Window { 
        accessToken: any; 
        access_token: any;
    }
}

class NavMenu extends React.Component<ConfigProps, LoginState> {
    public state: LoginState = {
        isOpen: false,
        isLoggedIn: false
    };
   
    constructor(props: any){
        super(props);
    }

    public render() {
        const { isLoggedIn, name } = this.state;
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light sticky="">
                    <Container>
                        <NavbarBrand className={this.state.isOpen ? 'hide' : ''} to="/" style={{padding: '0 10px', minWidth: 140}}><a href="/"><img src="/images/logo.png" height="50px" /> Green<span style={{color: '#ffbf00'}}>Gold</span></a></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" style={{position: 'absolute', top: 10}}/>
                            <Collapse className="d-sm-inline-flex" isOpen={this.state.isOpen} navbar style={{position: 'relative', display: 'inline-flex !important'}}>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem>
                                        <NavLink onClick={this.toggle} href="/" ><i className='fa fa-home'/> Home</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={this.toggle} href="/understand-ethiopian-coffee-regions" ><i className='fa fa-leaf'/> Coffee</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={this.toggle} href="/sourcing"><i className='fa fa-gear'/> Sourcing coffee</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={this.toggle} href="/cupping"><i className='fa fa-coffee'/> Tasting/Cupping</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={this.toggle} href="/about"><i className='fa fa-users'/> About us</NavLink>
                                    </NavItem>
                                </ul>
                            </Collapse>
                            <div className="logIn">
                                {/* isLoggedIn ? 
                                    <div>
                                        <span>Hello, {name}</span> <a href="javascript:void(0)" onClick={this.logout}>Signout</a>
                                    </div> : 
                                    <div>
                                        <a href="javascript:void(0)" onClick={this.signIn}>Sign in </a> {<span style={{ padding: '0 10px'}}> or </span> <a href="javascript:void(0)" onClick={this.signIn}>Sign up </a>}
                                    </div> 
                                */}
                            </div>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state: ApplicationState) => state.authConfig, // Selects which state properties are merged into the component's props
    ConfigStore.actionCreators // Selects which action creators are merged into the component's props
  )(NavMenu as any);