import { Theme } from "./Theme";
import { ContentProps } from "./components/contents/GenericContent";
import { ContentGroupProps } from "./components/Page";

export const styles = (theme: Theme) => ({
    wrapper: {
        padding: (props: ContentProps) => (props.style && props.style.padding) || theme.padding,
        margin: (props: ContentProps) =>  '0 auto',
        backgroundColor: (props: ContentProps) => {
            if(props.style && props.style.backgroundImage) {
                return 'transparent';
            }
            return (props.style && props.style.backgroundColor) || ( props.type === 'banner' ? theme.banner.bg : theme.text.bg)
          },
        color: (props: ContentProps) => (props.style && props.style.color) || (props.type === 'banner' ? theme.banner.fg: theme.text.color),
        textAlign:  (props: ContentProps) => (props.style && props.style.textAlign) || "center",
        fontSize: (props: ContentProps) => (props.style && props.style.fontSize) || 16
    },
    title: {
      fontSize: 30,
      fontWeifht: 900,
    },
    subTitle: {
        fontSize: 15,
        fontWeifht: 100,
        padding: "10px",
        marginTop: "-10px",
    },
    linkContainer: {
        padding: (props: ContentProps) => (props.link && props.link.style && props.link.style.padding) || "15px",
        margin: (props: ContentProps) => (props.link && props.link.style && props.link.style.margin) || "20px 0 20px 0",
        marginLeft: -10
    },
    link: {
        padding: (props: ContentProps) => (props.link && props.link.style && props.link.style.padding) || theme.link.padding,
        backgroundColor: (props: ContentProps) => (props.link && props.link.style && props.link.style.backgroundColor) || theme.link.bg,
        color: (props: ContentProps) => (props.link && props.link.style && props.link.style.color) || theme.link.fg,
        border: 'solid 1px',
        "&:hover": {
            textDecoration: "none",
            color: (props: ContentProps) => (props.link && props.link.style && props.link.style.backgroundColor) || theme.link.bg,
            backgroundColor: (props: ContentProps) => (props.link && props.link.style && props.link.style.color) || theme.link.fg,
        },
        borderRadius: 3,
        withTemplates: ``
    },
    img: {
        maxWidth: "100%",
    },
    row: {
        padding: 10
    },
    icon: {
        color: theme.color,
        fontSize: 36,
    },
    container: {
        padding: 0,
    }
  } as unknown as any);

  export const ContentGroupStyles =  (theme: Theme) => ({
    ...styles(theme),
    wrapper: {
      padding: (props: ContentGroupProps) => (props.style && props.style.padding) || 20,
      margin: (props: ContentGroupProps) =>  'auto',
      backgroundImage: (props: ContentGroupProps) => {
        const banner = props.contents && props.contents.find(c => c.type === 'banner');
        if(banner && banner.style && banner.style.backgroundImage) {
            return banner.style.backgroundImage.url;
        }
      },
      backgroundColor: (props: ContentGroupProps) => {
        const banner = props.contents && props.contents.find(c => c.type === 'banner');
        if(banner && banner.style && banner.style.backgroundImage) {
            return 'transparent';
        }
        return banner ? (banner && banner.style && banner.style.backgroundColor || theme.banner.bg) : theme.text.bg;
      },
      color: (props: ContentGroupProps) => (props.contents && props.contents.find(c => c.type === 'banner') ? theme.banner.fg: theme.text.color),
      textAlign:  (props: ContentGroupProps) => (props.style && props.style.textAlign) || "center",
      minHeight: (props: ContentGroupProps) => {
        const banner = props.contents && props.contents.find(c => c.type === 'banner');
        if(banner && banner.style && banner.style.backgroundImage) {
            return banner.style.backgroundImage.height;
        }
      }, 
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100vw',
    },
    row: {
      padding: "5px 0px",
    }
  });


