import 'bootstrap/dist/css/bootstrap.css';

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducers } from './store';
import { ThemeProvider } from 'theming';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Page from './components/Page';

export const theme = {
  color: '#173C4E',
  link: {
      fg: '#ffffff',
      bg: 'rgb(23, 60, 78)' /*'#b10707'*/,
      padding: '15px 20px',
      border: 'solid'
  },
  text: {
      color: "#24292e",
      bg: "#ffffff",
  },
  banner: {
      fg: '#ffffff',
      bg: '#000000',
      padding: '10px 20px',
  },
  padding: "10px",
};

export const store = configureStore({
  reducer: {
    ...reducers
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page path="home" />,
  },
  {
    path: "/home",
    element: <Page path="home" />,
  },
  {
    path: "/sourcing",
    element: <Page path="sourcing" />,
  },
  {
    path: "/cupping",
    element: <Page path="cupping" />,
  },
  {
    path: "/about",
    element: <Page path="about" />,
  },
  {
    path: "/sample-requests",
    element:  <Page path="sample_requests" />,
  },
  {
    path: "/quality-assessment",
    element: <Page path="quality_assessment" />,
  },
  {
    path: "/understand-ethiopian-coffee-regions",
    element: <Page path="understand_ethiopian_coffee_regions" />,
  },
  {
    path: "/history-of-coffee",
    element: <Page path="history_of_coffee" />,
  }
]);
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router}>
          </RouterProvider>
        </ThemeProvider>
    </Provider>,
  </React.StrictMode>,
)
